import type { NextPage } from 'next'
import { GetServerSidePropsContext } from 'next'
import { UserType } from '@prisma/client'
import { routes } from 'routes'
import { getUserType, shouldCompleteOnboarding, getSession } from 'lib/auth'
import { redirect } from 'lib/router'
import { SignInPage } from 'containers/signin/pages/SignInPage'

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
    const session = await getSession(ctx)
    const userType =  getUserType(session)
    if (session && userType) {
        if (userType === UserType.COMPANY_OWNER || userType === UserType.COMPANY_MEMBER) {
            // TODO [2639]: we should not do this here
            if (await shouldCompleteOnboarding(session)) {
                return redirect(routes.onboarding.initial())
            } else {
                return redirect(routes.dashboard())
            }
        } else if (userType === UserType.POTENTIAL_COMPANY_OWNER) {
            return redirect(routes.onboarding.initial())
        } else if (userType === UserType.VENDOR) {
            return redirect(routes.vendor.home())
        } else if (userType === UserType.INVESTOR) {
            return redirect(routes.investor.home())
        } else {
            return {
                notFound: true,
            }
        }
    }

    return {
        props: {},
    }
}

const Page: NextPage = () => {
    return <SignInPage />
}

export default Page
